<template>
<div>
    <v-toolbar tile outlined flat color="primary" dark>
        <v-toolbar-title>
            <h4>
                حجز موعد
            </h4>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn outlined @click="$router.go(-1)">
            <h4>رجوع</h4>
            <v-icon>
                fi fi-rr-arrow-small-left
            </v-icon>
        </v-btn>
    </v-toolbar>
    <v-divider/>
    <v-card color="primary" dark outlined tile>
        <v-form ref="form" v-model="valid">
            <v-container>
                <v-row class="text-center mx-4" justify="center" align="center">
                    <v-col cols="12">
                        <h1>حجز موعد</h1>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="[required()]" v-model="message.fullName" label="اسمك الثلاثي" filled outlined  hide-details="auto" />
                    </v-col>
                    <aAutoComplete :loading="$global.state.loading" :fieldTitle="false" v-model="message.clinicId" :items="clinics" item-text="name" item-value="id" title="العيادة" :cols="12" :md="6" :required="true" />
                    <v-col cols="12" md="6">
                        <v-text-field v-model="message.email" label="البريدالالكتروني" filled outlined  hide-details="auto" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="[required()]" dir="ltr" v-model="message.phoneNumber" label="رقم الهاتف" filled outlined  hide-details="auto" />
                    </v-col>
                    <aDatePicker v-model="message.date" title="تاريخ الزيارة" :cols="12" :md="12"  :required="false" :fieldTitle="false" />
                    <v-col cols="12" md="12">
                        <v-textarea v-model="message.note" label="ملاحظات" filled outlined  hide-details="auto" />
                    </v-col>
                    <v-col cols="12">
                        <v-btn x-large rounded class="btn" @click="sendMessage()" :loading="$global.state.loading" :disabled="!valid">
                            <h3 class="mx-8">حجز</h3>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-card>
</div>
</template>

<script>
import validations from '@/utils/validations'
export default {
    created() {
        this.getClinics()
    },

    methods: {

        async getClinics() {
            this.loading = true
            try {
                let res = await this.$http.get(`Clinic`)
                this.clinics = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async sendMessage() {
            this.$global.state.loading = true
            this.$http.post(`Reservation`, this.message).then(res => {
                this.$service.success('تم الحجز بنجاح')
                this.message = {
                    "fullName": "",
                    "clinicId": "",
                    "phoneNumber": "",
                    "email": "",
                    "note": ""
                }
                this.$refs.form.resetValidation()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

    },

    data() {
        return {
            valid: false,
            ...validations,
            clinics: [],
            message: {
                "fullName": "",
                "clinicId": "",
                "phoneNumber": "",
                "email": "",
                "note": ""
            },
        }
    },
};
</script>
